import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>
      <Typography paragraph={true}>
        To discuss your requirements in more detail, please get in touch with
        one or more firms of your choice.
      </Typography>
      <Typography paragraph={true}>
        An email with the above prices and contact details has been sent to you,
        so you can refer to it later at your convenience.
      </Typography>
      <Typography paragraph={true}>
        If you need more information or help in your house purchase, please call
        us on 0330 088 2050
      </Typography>
    </>
  );
}

export default ResultsFooter;
